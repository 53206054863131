.footer {
    background: $light-gray;
    color: #6c757d ;
    text-align: center;
    font-weight: 600;
    cursor: default;

    .footer-scoutas-logo {
        width: 120px;
        height: 120px;

        img{
            width: 100%;
        }
    }

    #contact-list {
        margin-top: 2rem;
        list-style: none;
        text-align: center;
        cursor: pointer;

        a {
            text-decoration: none;
            color: $green;
            font-size: $font-size*0.75;
        }
    }

    span {
        padding-top: 1rem;
        width: 80%;
    }

    .footer-social-media {
        list-style: none;


    }

    hr {
        border: 1px solid $white;
        border-radius: 3px;
        opacity: 1;
    }
}
