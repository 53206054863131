.timeline {

    margin: auto;
    width: 100%;
    position: relative;

    /*
    .timeline-btns {

        .container-fluid {

            box-shadow: 0px 1px 15px 0.1px $light-gray;
            margin-bottom: 2rem;

            .col {
                margin: 0;
                padding: 0;
            }
        }
    }

     */
    .year-selector {
        list-style: none;
        display: flex;
        justify-content: center;
        margin-left:-50px;
        padding: 0!important;
        font-weight: 600;

        .year-selector-btn {
            width: 20%;
            height: 50px;
            background: $pure-white;
            position: relative;
            margin: 0 5px;
            text-align: center;
            line-height: 50px;
            font-size: 1rem;
            transition: all 0.6s cubic-bezier(0.3, 0.84, 0.44, 1);
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                left: 0; bottom: 0; width: 0; height: 0;
                border-left: 25px solid $white;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
            }

            &:before {
                content: '';
                position: absolute;
                right: -25px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 25px solid $pure-white;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

            }

            &:hover:before {
                border-left: 25px solid $orange;
                transform: scale(1.05, 1.05) translateX(5px);
            }

            &:hover:after {
                transform: scale(1.06, 1.05) translateX(-1px);
            }
        }

        .year-selector-btn-spacer-before {
            width: 30%;
            height: 50px;
            background: $light-gray;
            position: relative;
            margin: 0 5px;

            &:before {
                content: '';
                position: absolute;
                right: -25px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 25px solid $light-gray;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
            }
        }

        .year-selector-btn-spacer-after {
            width: 30%;
            height: 50px;
            background: $light-gray;
            position: relative;
            margin: 0 5px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 25px solid $white;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
            }
        }

        #y-empty-1 {
            z-index: 17;
        }

        #y-2017 {
            z-index: 15;
        }
        #y-2018 {
            z-index: 14;
        }
        #y-2019 {
            z-index: 13;
        }
        #y-2020 {
            z-index: 12;
        }
        #y-2021 {
            z-index: 11;
        }

        .year-selector-btn:hover{
            background: $orange;
            color: $pure-white;
            transform: scale(1.05, 1.05);
        }

        .year-selector-btn.active{
            background: $green;
            color: $pure-white;

            &:before {
                content: '';
                position: absolute;
                right: -25px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 25px solid $green;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

            }
        }

    }

    .timeline-scale {

        position: relative;
        width: 70%;
        margin: auto;
        height: 100px;

        #timeline-dots {
            padding:0;
            margin:0;
            padding-top:10px;
            z-index:9999;
            position: absolute;
            width: 100%;
            left:-18px;

            li{
                list-style: none;
                float:left;
                //width: 10%;
                text-align: center;
                color: #aaaaaa;
                text-transform: uppercase;
                font-size: 11px;
                cursor: pointer;
                font-weight: 700;
                transition: all ease .2s;
                vertical-align: bottom;
                height: 59px;
                position: absolute;

            }

            li:after {
                content: "";
                display: block;
                margin: auto;
                width:36px;
                height: 36px;
                border-radius: 50%;
                border: solid 2px #aaa;
                transition: all ease .3s;
                background: $orange;
                background-image: url('/images/timeline/csi-liliom-black.svg');
            }

            li#timeline-dot.selected:after {
                background: $green;
                background-image: url('/images/timeline/csi-liliom.svg');
            }

            li:hover:after{
                background: $green;
                background-image: url('/images/timeline/csi-liliom.svg');
            }
        }
        /*
        .timeline-date-scale {

            padding:0;
            margin:0;
            padding-top:30px;
            padding-left:30px;
            z-index:9999;
            position: absolute;
            width: 100%;

            .timeline-date {
                height: 15px;
                border-left: 2px dotted black;
                list-style: none;
                float:left;
                width: 10%;
                text-align: center;
                color: #aaa;
                text-transform: uppercase;
                font-size: 11px;
                cursor: pointer;
                font-weight: 700;
                transition: all ease .2s;
                vertical-align: bottom;
                position: relative;
            }
        }*/

        #line {
            width: 100%;
            margin: auto;
            height: 5px;
            position: absolute;
            //left: 5%;
            top: 11px;
            z-index: 1;
            border-radius: 50px;
            transition: all ease .9s;

            hr {
                border:none;
                border-top:5px dotted $green;
                color:#fff;
                background-color:#fff;
                height:1px;
                width:100%;
            }
        }

        .line-scale-wrapper {
            margin: 0 auto;
            font-size: 0.8rem;
            position: relative;
            top: 40px;

            #line-scale {
                list-style: none;
                padding: 0;
                display: flex;
                justify-content: space-between;

                .line-scale-date {
                    margin: 0 10px;
                    height: 12px;
                    margin: -2px;
                    border:none;
                    border-radius: 5px;
                    border-left: 3px solid $green-op-25;

                    .line-scale-date-text {
                        text-align: center;
                        width:50px;
                        position: absolute;
                        margin-left: -25px;
                        margin-top: 1rem;
                        font-variant-caps: all-small-caps;
                        font-weight: 600;
                    }
                }

                .line-scale-date-end {

                    margin: 0 10px;
                    height: 12px;
                    margin: -2px;
                }
            }
        }
    }

    .timeline-content-wrapper {

        width: 70%;
        height: 600px;
        margin: 0 auto;

        .timeline-navigation {
            list-style: none;
            position: relative;
            cursor: pointer;

            .next {
                position: absolute;
                padding: 30px;
                right: 3.7%;
                top:12rem;
                content: '';
                width: 40px;
                height: 40px;
                background: #fff;
                box-shadow: 0 2px 5px .01px $light-gray;
                z-index: 10;

                .next-inner{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    top:22px;
                    left:20px;
                    background: $light-gray;
                    transform: rotate(45deg);
                }

                .next-inner:before {
                    content: '';
                    position: absolute;
                    width: 21px;
                    height: 3px;
                    top: 9px;
                    background: $light-gray;
                    transform: rotate(-90deg);
                }
            }

            .next:hover {
                box-shadow: 0 2px 5px 3px $light-gray;
            }

            .prev {
                position: absolute;
                padding: 30px;
                left: 3.7%;
                top:12rem;
                content: '';
                width: 40px;
                height: 40px;
                background: #fff;
                box-shadow: 0 2px 5px .01px $light-gray;
                z-index: 10;

                .prev-inner{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    top:22px;
                    right:20px;
                    background: $light-gray;
                    transform: rotate(135deg);
                }

                .prev-inner:before {
                    content: '';
                    position: absolute;
                    width: 21px;
                    height: 3px;
                    top: -9px;
                    background: $light-gray;
                    transform: rotate(90deg);
                }
            }

            .prev:hover {
                box-shadow: 0 2px 5px 3px $light-gray;
            }
        }

        .timeline-contents { //ol

            list-style: none;
            padding: 0;
            width: 100%;
            height: 100%;

            #timeline-content { //li
                display: none;
                left:0; right: 0;
                margin: 0 auto;
                position: absolute;
                width: 60%;
                -webkit-transform: translateX(300%);
                -moz-transform: translateX(300%);
                -ms-transform: translateX(300%);
                -o-transform: translateX(300%);
                transform: translateX(300%);

                .timeline-card {
                    width: 100%;
                    border-radius: 0 !important;
                    background-color: #fff !important;
                    border: none;
                    padding: 3rem;
                    margin: 0 auto;
                    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);

                    .card-head {

                        display: inline-flex;

                        .card-infos {
                            width: 80%;
                            margin: 0 auto;
                            font-variant-caps: all-small-caps;

                            .card-date {
                                font-variant-caps: all-small-caps;
                            }

                            .card-location {

                                padding: 0.3rem 0 0.3rem;
                                font-variant-caps: all-small-caps;
                            }

                            .card-data {

                                padding: 0.3rem 0 0.3rem;
                            }

                            .card-list {
                                display: inline-flex;

                                .card-list-element {
                                    display: block !important;
                                    margin: 2px;
                                    padding: 4px;
                                    border-radius: 2px;
                                    background: $orange;
                                    font-variant-caps: all-small-caps;
                                    color: $pure-white;
                                }
                            }
                        }
                        .card-logo {
                            position: absolute;
                            top: 10%;
                            right: 10%;
                            border-radius: 100%;
                            padding: 5px;
                            width: 100%;
                            max-width: 100px;
                            height: auto;
                        }
                    }

                    .card-btns {

                        width: 75%;
                        margin: 2rem auto 0;
                        font-size: $font-size;

                        .col {
                            padding: 0;

                            .btn {

                                &:focus {

                                    box-shadow: none;
                                    outline: 0;
                                }
                            }
                        }
                    }

                    .card-title {
                        padding: 1rem;
                        font-variant-caps: all-small-caps;
                        font-family: 'OpenSans-Regular', sans-serif;
                        color: $navbar-font;
                    }

                    p {
                        margin: 0 auto;
                        width: 70%;
                        text-align: center;
                        font-size: $font-size*0.85;
                    }
                }

                .timeline-card:after {
                    //display: block;
                    position: absolute;
                    bottom: -.625rem;
                    left: 1.25rem;
                    width: calc(100% - 2.5rem);
                    height: 2.1875rem;
                    background-color: #fff;
                    -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                    content: '';
                    z-index: -1;
                }
            }

            #timeline-content.selected {
                //display: block!important;
                left:0; right: 0;
                margin: 0 auto;
                position: absolute;
                width: 60%;
                z-index: 2;
                -webkit-transform: translateX(0%);
                -moz-transform: translateX(0%);
                -ms-transform: translateX(0%);
                -o-transform: translateX(0%);
                transform: translateX(0%);

            }
        }
    }
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(300%);
        -moz-transform: translateX(300%);
        -ms-transform: translateX(300%);
        -o-transform: translateX(300%);
        transform: translateX(300%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300%);
        -moz-transform: translateX(-300%);
        -ms-transform: translateX(-300%);
        -o-transform: translateX(-300%);
        transform: translateX(-300%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@keyframes cd-enter-bottom {
    0% {
        opacity: 0;
        -webkit-transform: translatey(300%);
        -moz-transform: translateY(300%);
        -ms-transform: translateY(300%);
        -o-transform: translateY(300%);
        transform: translateY(300%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}


@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(300%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(300%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-300%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@include media-breakpoint-down(sm) {
    .timeline {

        .year-selector {

            .year-selector-btn {
                padding-left: 8px;
                line-height: 50px;
                font-size: .8rem;
            }
        }

        .timeline-scale {

            width: 90%;
        }

        .timeline-content-wrapper {

            width: 100%;
            height: 600px;
            margin: 0 auto;

            .timeline-contents {
                #timeline-content {
                    width: 100%;

                    .timeline-card {
                        padding: 2rem;
                        .card-logo {
                            top: 5%!important;
                            right: 5%!important;
                            max-width: 80px!important;
                        }

                        .card-btns {

                            .col {

                                .btn {

                                    font-size: $font-size*0.8;

                                    &:focus {

                                    }
                                }
                            }
                        }
                    }
                }

                #timeline-content.selected {
                    width: 100%;
                }
            }
        }
    }
}
