.d_blockquote {
    width: 90%;
    margin: 3rem auto;
    position: relative;
    z-index: 1;
    padding: 2rem;
    background-color: $pure-white;
    -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);

    .blockquote_text {
        margin-bottom: 1rem;
    }
}
.d_blockquote:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -.625rem;
    left: 1.25rem;
    width: calc(100% - 2.5rem);
    height: 2.1875rem;
    //background-color: $white;
    -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
    z-index: -1;
}
