@import 'blocks/gallery';

.container-fluid.gallery {
    min-height: 100vh;
    margin: 1rem auto;
}

@include media-breakpoint-down(sm) {
    .container-fluid.gallery {
        padding: 0;
    }
}

