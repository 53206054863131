@font-face {
    font-family: 'Montserrat-Black';
    src: url("../../assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url("../../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url("../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraBoldItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url("../../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraLightItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: url("../../assets/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url("../../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-LightItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url("../../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url("../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url("../../assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ThinItalic';
    src: url("../../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}


