@import 'blocks/result-card-project-page';
@import 'blocks/video';
@import 'blocks/table';
@import 'blocks/accordion';
@import 'blocks/breadcrumb';
@import 'blocks/blockquote';

.project-landing {

    height: 100vh;
    //padding: 2rem;

    .project-banner {

        width: inherit;
        min-height: 100%;
        background-size: cover;
        background-position: center;

        .project-banner-text {
            position: absolute;
            width: inherit;
            bottom: 5%;

            h1 {

                text-align: right;
                padding: 5rem;
                //background: $white;
                font-size: 5em;
                font-variant-caps: all-small-caps;
                color: $white;
                text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
                font-family: 'Montserrat-ExtraBold', sans-serif;
            }
        }
    }
}

.project-page-content {

    position: relative;

    .results {

        background: $white;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        h2 {
            padding: 2rem;
        }
    }

    .data {
        padding-top: 3rem;
        height: 100vh;
        width: 100%;
        text-align: center;
        background-color: $pure-white;

        h2 {
            height: 10%;
            padding-bottom: 1rem;
        }

        .ratio {
            width: 80%;
            margin: 0 auto;

            iframe {

            }
        }
    }

    .blocks {
        background-color: $white;
        padding-top: 3rem;
        padding-bottom: 3rem;

        .btns {
            width: 60%;
            margin: 2rem auto;
        }
    }
}

@include media-breakpoint-down(lg) {
    .project-landing {

        height: 80vh;

        .project-banner {

            .project-banner-text {

                bottom: 30%;

                h1 {
                    font-size: 2.25rem;
                    padding: 3rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .project-landing {

        .project-banner {

            .project-banner-text {

                h1 {

                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .project-landing {

        height: 80vh;

        .project-banner {

            .project-banner-text {

                bottom: 25%;

                h1 {
                    padding: 1rem;
                }
            }
        }
    }
}

.ckeditor img{
    @include img-fluid;
}

.video-container-16-9 {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container-16-9::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container-16-9 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
