.section-title {
    color: $green;
    font-family: Montserrat-ExtraBold, sans-serif;
    font-size: $font-size*2.5;
}

h1{
    text-align: center;
    color: $green;
    font-family: Montserrat-ExtraBold, sans-serif;
}

h2 {
    text-align: center;
    color: $green;
    font-family: Montserrat-ExtraBold, sans-serif;
}

h3 {
    text-align: center;
}

h4 {
    text-align: center;
}

h5 {
    text-align: center;
}

h6 {
    text-align: center;
    font-size: $font-size*1.1;
}

p {
    font-size: $font-size;
    margin: 1rem;
}

a {
    text-decoration: none;
    font-size: $font-size;
    color: $green;
    font-weight: 600;
}
