.container-landing-img {
    width: 100%;
    background-image: url('/images/landing.jpg');
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    position: relative;

    #landing-img-overlay {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 2s ease-in-out;
        //background: radial-gradient(circle at center,rgba(17,17,17,1) 0%,transparent 40%,transparent 100%);
        background: $black-gray;
        z-index: 10000;
        //mix-blend-mode: color;
    }

    #landing-img-overlay-text {
        color: $pure-white;
        font-size: 3em;
        font-weight: 900;
        line-height: 1.1;
        text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
        font-family: 'Montserrat-ExtraBold', sans-serif;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10001;
        text-align: center;
    }

    #project {
        visibility: hidden;
        position: absolute;
        bottom: 40px;
        z-index: -1000;
    }
}

@include media-breakpoint-down(sm) {
    .container-landing-img {
        background-image: url('/images/landing_vertical2.jpg');
        background-attachment: initial;
        background-size: cover;
    }
}
