.parallax {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
    }

    #text {
        position: relative;
        padding-bottom: 100px;
        color: white;
        z-index: 1;

        h2 {
            color: $pure-white;
            font-size: 4rem;
            font-weight: 900;
            line-height: 1.1;
            text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
            font-family: 'Montserrat-ExtraBold', sans-serif;
        }

        h4 {
            font-size: 2.5rem;
            font-weight: 900;
            line-height: 1.1;
            text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
            font-family: 'Montserrat-ExtraBold', sans-serif;
        }
    }

    #march {
        z-index: 2;
    }

    #sky {
        z-index: 0;
    }
}

.parallax:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    //background: linear-gradient(to top, $light-gray, transparent);
    z-index: 10000;
}

/*
.parallax:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $light-gray;
    z-index: 10000;
    mix-blend-mode: color;
}
*/

@include media-breakpoint-down(lg) {
    .parallax {
        #text {
            h4 {
                margin: 0 20px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .parallax {
        #text {

            h2{
                font-size: 2.5rem;
            }
            h4 {
                font-size: 1.5rem;
                margin: 0 20px;
            }
        }
    }
}
