.video {
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);

    &:after {
        display: block;
        position: absolute;
        bottom: -.625rem;
        left: 1.25rem;
        width: calc(100% - 2.5rem);
        height: 2.1875rem;
        background-color: $white;
        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        content: '';
        z-index: -1;
    }
}

@include media-breakpoint-down(lg) {
    .video {
        width: 90%;
    }
}
