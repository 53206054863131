#results {

    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0;

    h2 {
        padding-bottom: 5rem;
    }

    .row {
        width: 100%;
        margin: 0 auto;

        .mosaic {
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            height: 100%;
            margin-bottom: 15px;
            position: relative;
            width: 100%;

            .mosaic-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                content: '';
            }

            .mosaic-hide {
                opacity: 0;
                width: 100%;
                height: 100%;
                display: none;
                filter: blur(0);

                a {
                    //pointer-events: none;
                    //cursor: default;
                    color: $pure-white;
                    text-decoration: none;
                    font-weight: 400;

                    &:hover, a:focus, a:active {
                        text-decoration: none;
                        color:$pure-white;
                    }
                }
            }
        }

        .mosaic:hover{

            .mosaic-bg {
                filter: blur(1.5px);
                transition: all 0.3s cubic-bezier(.17,.67,.83,.67);
                //transform: scale(1.01,1.01);
                box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 30%);
            }

            .mosaic-bg:after {
                content: '';
                position: absolute;
                left:0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: $black-gray;
                z-index: 10000;
                mix-blend-mode: color;
            }

            .mosaic-hide {
                position: relative;
                transition: opacity 1s ease-in-out;
                display: block;
                opacity: 1;
                z-index: 10001;
                filter: blur(0)!important;

                .mosaic-hide-text {
                    //border-radius: 2px;
                    background: $purple;
                    color: $white;
                    font-size: 1rem;
                    padding: 10px 15px;
                    position: absolute;
                    right: -10px;
                    text-align: center;
                    top:20px;
                }

                .mosaic-hide-btn {
                    padding-right: 30px;
                    padding-left: 30px;
                    position: absolute;
                    bottom:20px;
                    left:-10px;
                }
            }
        }

        #first-mc {
            height: 71.7vh;

            #first-mc-bg {
                background-image: url('/images/results/result-2.jpg');
            }
        }

        #second-mc {
            height: 40vh;

            #second-mc-bg {
                background-image: url('/images/results/result-1.jpg');
                background-size: cover;
                background-position: center;
                content: '';
            }
        }

        #third-mc {
            height: 30vh;

            #third-mc-bg {
                background-image: url('/images/results/result-4.jpg');
                background-size: cover;
                background-position: center;
                content: '';
            }
        }

        #fourth-mc {
            height: 25vh;

            #fourth-mc-bg {
                background-image: url('/images/results/result-3.jpg');
                background-size: cover;
                background-position: center;
                content: '';
            }
        }

        #fifth-mc {
            height: 45vh;

            #fifth-mc-bg {
                background-image: url('/images/results/result-5.jpg');
                background-size: cover;
                background-position: center;
                content: '';
            }
        }

        #first_mc:after,
        #third_mc:after,
        #fifth_mc:after {

        }

        #col-1 {
            padding:0;
            padding-right: calc(var(--bs-gutter-x) / 2);
        }

        #col-2 {
            padding:0;
            padding-right: calc(var(--bs-gutter-x) / 2);
            padding-left: calc(var(--bs-gutter-x) / 2);
        }

        #col-3 {
            padding:0;
            padding-left: calc(var(--bs-gutter-x) / 2);
        }
    }
}

@media only screen and (max-width: 1010px) {
    #results {
        width: 90%;
        margin: 0 auto;

        .row {

            .mosaic {
                margin-bottom: 15px;

                a {
                    pointer-events: fill;
                    position: absolute;
                    cursor: default;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    z-index: 10002;
                }

                .mosaic-hide {
                    position: relative;
                    transition: none;
                    display: block;
                    opacity: 1;
                    z-index: 10001;
                    filter: none;

                    .mosaic-hide-text {
                        background: $purple;
                        color: $white;
                        font-size: .75rem;
                        padding: 10px 15px;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        bottom: 10%;
                        top: initial;
                    }

                    .mosaic-hide-btn {
                        display: none;
                    }
                }
            }

            .mosaic:hover {

                a {
                    pointer-events: fill;
                    position: absolute;
                    cursor: default;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    z-index: 10002;
                }

                .mosaic-bg {
                    filter: none;
                    transition: none;
                    //transform: scale(1.01,1.01);
                    box-shadow: none;
                }

                .mosaic-bg:after {
                    content: none;
                }

                .mosaic-hide {
                    position: relative;
                    transition: none;
                    display: block;
                    opacity: 1;
                    z-index: 10001;
                    filter: none;

                    .mosaic-hide-text {
                        //border-radius: 2px;
                        background: $purple;
                        color: $white;
                        font-size: .75rem;
                        padding: 10px 15px;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        bottom: 10%;
                        top: initial;
                    }

                    .mosaic-hide-btn {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    #results {
        width: 100%;
        margin: 0 auto;

        .row {
            --bs-gutter-x: 0 !important;

            .mosaic {
                margin-bottom: 0;

                a {
                    pointer-events: fill;
                    position: absolute;
                    cursor: default;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    z-index: 10002;
                }

                .mosaic-hide {
                    position: relative;
                    transition: none;
                    display: block;
                    opacity: 1;
                    z-index: 10001;
                    filter: none;

                    .mosaic-hide-text {
                        background: $purple;
                        color: $white;
                        font-size: .75rem;
                        padding: 5px 5px;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        bottom: 10%;
                        top: initial;
                    }

                    .mosaic-hide-btn {
                        display: none;
                    }
                }
            }

            .mosaic:hover {

                a {
                    pointer-events: fill;
                    position: absolute;
                    cursor: default;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    z-index: 10002;
                }

                .mosaic-bg {
                    filter: none;
                    transition: none;
                    //transform: scale(1.01,1.01);
                    box-shadow: none;
                }

                .mosaic-bg:after {
                    content: none;
                }

                .mosaic-hide {
                    position: relative;
                    transition: none;
                    display: block;
                    opacity: 1;
                    z-index: 10001;
                    filter: none;

                    .mosaic-hide-text {
                        //border-radius: 2px;
                        background: $purple;
                        color: $white;
                        font-size: .75rem;
                        padding: 5px 5px;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        bottom: 10%;
                        top: initial;
                    }

                    .mosaic-hide-btn {
                        display: none;
                    }
                }
            }
        }
    }

    #first-mc {
        height: 70vh!important;
    }

    #col-1 {
        padding:0;
    }

    #col-2 {
        padding:0;
    }

    #col-3 {
        padding:0;
    }
}
