.section.map {

    .heading {

        text-align: center;
        padding: 3rem;

        .section-subtitle {
            padding: 1.5rem 0 1rem;
        }

        .section-text {

            width: 75%;
            margin: 1rem auto 0;
        }
    }

    #map_territorial_filter_wrapper {

        width: 100%;
        height: 14%;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 0;
        border: 0;
        background: $pure-white;

        #map_territorial_filter {

            width: 80%;
            margin: 0 auto;
            padding: 0 !important;
            border: 0;
            //background: $white;
            //box-shadow: $shadow;
            position: relative;

            .map-territorial-btns {
                list-style: none;
                width: 100%;
                height: 50%;
                padding: 0 !important;
                border: 0;
                margin: 0;

                .map-territorial-btn {
                    margin: 10px;
                    border: 0;
                    display: inline-block;
                    width: 8%;
                    height: auto;
                    border-radius: 100%;
                    box-shadow: $shadow-short;
                    transition: all .1s ease-in-out;

                    img {
                        width: 95%;
                        height: auto;
                        padding: 2.5%;
                    }

                    &:hover {
                        box-shadow: 0 2px 5px 0.01px #c5c5c5;
                        transition: all .1s ease-in-out;
                        transform: scale(1.3, 1.3);
                    }

                    &.active {
                        transition: all .1s ease-in-out;
                        border: 4px solid $green;
                        box-shadow: 0 2px 5px 0.01px #c5c5c5;
                    }
                }
            }
        }
    }

    #map {
        width: 90%;
        height: 80vh;
        //margin: 5rem auto 0;
        margin-top: 5rem;
        margin-left: auto;
        margin-right: auto;

        .gm-style-iw {
            position: absolute;
            transform: none !important;
            top: initial !important;
            bottom: 0 !important;
            width: 500px !important;
            left: -250px !important;
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%) !important;
            border-radius: 0 !important;
            overflow: visible !important;
            padding: 0;

        }

        .gm-style-iw:before {
            display: block;
            position: absolute;
            bottom: -.625rem;
            left: 1.25rem;
            width: calc(100% - 2.5rem);
            height: 2.1875rem;
            background-color: $pure-white;
            -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            content: '';
            z-index: -1;
        }

        .iw-content {

            .iw-image {
                width: 100%;
                height: auto;
                margin-bottom: 1rem;
            }

            .iw-content-body {

                padding: 1rem;
                font-variant-caps: all-small-caps;
                font-size: $font-size;

                .iw-content-date {
                    font-size: $font-size*1.15;
                }

                .iw-content-location {

                    padding: 0.3rem 0 0.3rem;

                    .iw-content-coord {

                        margin: 0.15rem;
                    }
                }

                .iw-content-datas {

                    padding: 0.3rem 0 0.3rem;

                    .iw-content-data {

                        margin: 0.15rem;
                    }

                    .iw-content-tags {
                        list-style: none;
                        //text-align: center;
                        margin-bottom: 1rem;

                        .iw-content-tag {
                            font-weight: 500;
                            margin: 1px;
                            padding: 2px 4px;
                            display: inline-block;
                            color: $pure-white;
                            background: $orange;
                        }
                    }
                }

                .iw-content-text {

                    margin-bottom: 2rem;
                    font-variant-caps: initial;
                    font-size: $font-size*0.8;
                }

                .iw-content-link {

                    font-size: $font-size*0.8;
                    text-decoration: none;
                    font-variant-caps: initial;
                    background: $purple;
                    padding: 0.5rem;
                    color: $white;
                    border-radius: 2px;

                    &:hover {
                        box-shadow: $shadow;
                    }
                }

                .iw-title {
                    font-weight: initial;
                }

                .iw-spacer {
                    height: 50px;
                }
            }
        }
    }

    #map-category {

        margin-left: 3%;
        margin-bottom: 3%;
        width: 12rem;
        height: 12rem;
        background: rgba(170, 170, 170, 0.7) !important;
        transition: all 0.5s ease-in-out;
        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        text-align: center;

        #map-category-inner {

            background: #f3f3f3;
            margin: auto;
            width: 6rem;
            height: 6rem;
            transform: translateY(50%);
            transition: opacity 0.5s ease-in-out;

            #map-category-inner-img {
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background-image: url('/images/map/csi-terkep.svg');
            }
        }

        #map-category-inner-cs {
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            position: relative;
            margin: auto;
            width: 100%;
            height: 100%;
            //transform: translateY(12%);
            //padding: 5px;

            #map-category-inner-cs-close-btn {
                display: none;
            }

            .btn.map {
                font-size: .3rem;
                font-weight: 500;
                padding: 2px 5px;
                border: none;
                border-radius: 2px;
                background: $white;
                text-align: center;
                color: $navbar-font;
                margin-bottom: .5rem;
                box-shadow: 0 2px 15px 0.1px $middle-gray;

                &:hover {
                    background: $orange;
                    color: $pure-white;
                }

                &.active {
                    background: $green;
                    color: $pure-white;
                }
            }

            h6 {
                font-size: .35rem;
            }

            .map-category-inner-c {
                position: relative;
            }

            .legend-table2 {

                width: 40%;
                margin: 0 auto 0;
                //background-color: $white;
                text-align: center;
                vertical-align: middle;
                //box-shadow: $shadow;
                font-variant-caps: all-small-caps;
                font-size: 0.4rem;
                font-weight: 500;
                color: $pure-white;
                transition:all 1s ease-in-out;
                border-radius: 2px;
                overflow: hidden;
                cursor: default;

                //border-color: $light-gray;
                //border: 0.1px solid #dee2e6;

                .border-right {
                    border-right: thin/2 solid #dee2e6!important;
                }

                tr, td, th{
                    border: none;
                    //border-color: #dee2e6;
                    //border-color: #dee2e6;
                }

                tr {
                    border-bottom: thin/2 solid #dee2e6!important;
                }

                td {
                    //background-color: rgba(170, 170, 170, 0.7);
                    background-color: $pure-white;
                }

                th {
                    color: $navbar-font;
                    background-color: $white;
                }

                td, th {
                    padding: 1px;
                }

                .legend-table-icon {

                    width: 12px;
                    height: auto;
                }

                &:hover {
                    transition:all 1s ease-in-out;
                    //transform: scale(1.1,1.1);
                }
            }
        }
    }
}
.legend-table {

    width: 30%;
    margin: 0 auto 2rem auto;
    background-color: $white;
    text-align: center;
    vertical-align: middle;
    box-shadow: $shadow;
    font-variant-caps: all-small-caps;
    font-size: $font-size;
    transition:all 1s ease-in-out;

    td {
        background-color: $pure-white;
    }

    td, th {
        padding: 0.2rem 0.2rem;
    }

    .legend-table-icon {

        width: 35px;
        height: auto;
    }

    &:hover {
        transition:all 1s ease-in-out;
        //transform: scale(1.1,1.1);
    }
}

@include media-breakpoint-down(xl) {
    .container-fluid.map {
        .section.map {
            #map_territorial_filter_wrapper {

                #map_territorial_filter {
                    width: 100%;

                    .map-territorial-btns {

                        .map-territorial-btn {
                            margin: 10px;
                            width: 8%;
                        }
                    }
                }
            }
        }
        .legend-table {
            .legend-table-icon {

            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .container-fluid.map {
        .section.map {
            #map_territorial_filter_wrapper {

                #map_territorial_filter {
                    width: 100%;

                    .map-territorial-btns {

                        .map-territorial-btn {
                            margin: 5px !important;
                            width: 9%;
                        }
                    }
                }
            }

            #map {
                width: 100%;
                height: 90vh;
                //margin: 5rem auto 0;
                margin: 0 auto;

                .gm-style-iw {
                    position: absolute;
                    transform: none !important;
                    top: initial !important;
                    bottom: 0 !important;
                    width: 400px !important;
                    left: -125px !important;
                    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%) !important;
                    border-radius: 0 !important;
                    overflow: visible !important;
                }

                #map-category {
                    #map-category-inner-cs {

                        #map-category-inner-cs-close-btn {
                            display: initial;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: -5px;
                            right: 25px;
                            background-image: url('/images/map/close-btn-white.svg');
                            background-size: cover;

                            &:hover {
                                background-image: url('/images/map/close-btn.svg');
                            }
                        }

                        .btn.map {
                            &:hover {
                                background: $green;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .legend-table {

        }
    }
}

@include media-breakpoint-down(md) {
    .container-fluid.map {
        .section.map {
            #map_territorial_filter_wrapper {

                #map_territorial_filter {
                    width: 100%;

                    .map-territorial-btns {

                        .map-territorial-btn {
                            margin: 5px;
                            width: 10%;
                        }
                    }
                }
            }
        }
        .legend-table {
            width: 70%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .container-fluid.map {

        padding: 0;

        .section.map {

            #map {
                width: 100%;

                .gm-style-iw {
                    position: absolute;
                    transform: none !important;
                    top: initial !important;
                    bottom: 0 !important;
                    width: 500px !important;
                    left: -125px !important;
                    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%) !important;
                    border-radius: 0 !important;
                    overflow: visible !important;
                    padding: 0;
                }
            }

            .heading {

                padding: 1.5rem;

                .section-text {

                    width: 100%;
                }
            }

            #map_territorial_filter_wrapper {

                #map_territorial_filter {

                    width: 100%;

                    .map-territorial-btns {

                        .map-territorial-btn {
                            margin: 0;
                            width: 20%;
                            height: auto;
                            padding: 0px;

                            img {
                                width: 98%;
                                height: auto;
                                padding: 1%;
                            }

                            &:hover {
                                background: $pure-white;
                            }

                            &.active {
                                background: $green;
                            }
                        }
                    }
                }
            }
        }
        .legend-table {
            width: 80%;

            font-size: $font-size;

            .legend-table-icon {
                width: 30px;
            }
        }
    }
}
