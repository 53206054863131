.d_accordion {

    width:70%;
    margin: 3rem auto;
    background: $pure-white;
    box-shadow: $shadow;

    .accordion-button.collapsed {
        background: $pure-white;
    }

    .accordion-button:not(.collapsed) {
        background: $pure-white;
        color: $navbar-font;

        &:after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23343b43'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
        }
    }

    .accordion-button:focus {
        box-shadow: none;
        border-color: rgba(0, 0, 0, .125);
    }
}
