.section.gallery {
    margin: 0 auto;
    text-align: center;

    h2 {
        padding: 2rem;
    }

    .row {
        margin: auto;
        justify-content: center;
        width: 100%;

        .card {
            position: relative;
            background: $pure-white;
            border: none;
            margin: 2rem auto;
            padding: 0;
            min-width: 200px;
            max-width: 250px;
            height: 500px;
            box-shadow: $shadow;

            .index-gallery-card-link {
                width: 100%;
                height: 100%;
                text-decoration: none;

                .index-gallery-card-image {
                    height: 80%;
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                }

                .index-gallery-card-title {
                    font-family: 'Montserrat-ExtraBold', sans-serif;
                    font-size: $font-size*1.25;
                    color: $green;
                }

                &:hover {
                    background: $white;
                }
            }
        }
        .card:after {
            display: block;
            position: absolute;
            bottom: -.625rem;
            left: 1.25rem;
            width: calc(100% - 2.5rem);
            height: 2.1875rem;
            background-color: $pure-white;
            -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            content: '';
            z-index: -1;
        }
    }
}

@include media-breakpoint-down(lg) {
    .section.gallery {
        .row {
            .card {
                min-width: 250px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .section.gallery {
        .row {
            .card {
                min-width: 250px;
            }
        }
    }
}
