.project-card{
    width: 100%;
    padding-bottom: 5rem;
    .card {
        border-radius: 0 !important;
        background-color: #fff!important;
        border: none;
        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        //box-shadow: 0 .125rem .25rem rgba(black, .075);
        flex-direction: row;
        align-items: center;

        .card-body {
            width: 40%;
            .card-title {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 2rem;
            }
            .card-text {
                width: 80%;
                margin-bottom: 2rem;
                margin-left: auto;
                margin-right: auto;
                //text-align: justify;
                font-size: $font-size*.9;
            }
            a {
                text-decoration: none;
                color: $white;
            }
        }
        .card-img {
            min-width: 500px;
            min-height: 500px;
            width: 60%;
            height: auto;
            border-radius: 0!important;
            background-size: cover;
            background-position: center;
        }
    }

    .card:after {
        display: block;
        position: absolute;
        bottom: -.625rem;
        left: 1.25rem;
        width: calc(100% - 2.5rem);
        height: 2.1875rem;
        background-color: #fff;
        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        content: '';
        z-index: -1;
    }
}

@include media-breakpoint-down(lg) {
    .project-card{

        .card {

            .card-body {

                .card-title {

                }
                .card-text {

                }
                a {

                }
            }
            .card-img {

                min-height: 500px;
                min-width: 300px;
                border-radius: 0!important;
                background-size: cover;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .project-card{
        width: 100%;
        padding-bottom: 5rem;
        .card {
            border-radius: 0 !important;
            background-color: #fff!important;
            border: none;
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            //box-shadow: 0 .125rem .25rem rgba(black, .075);
            flex-direction: column;
            align-items: center;

            .card-body {
                width: 100%;
                .card-title {
                    margin-bottom: 2rem;
                }
                .card-text {
                    width: 90%;
                    margin-bottom: 2rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                a {
                    text-decoration: none;
                    color: $white;
                }
            }
            .card-img {
                min-width: 300px;
                min-height: 300px;
                width: 100%;
                height: auto;
                border-radius: 0!important;
                background-size: cover;
            }
        }

        .card:after {
            display: block;
            position: absolute;
            bottom: -.625rem;
            left: 1.25rem;
            width: calc(100% - 2.5rem);
            height: 2.1875rem;
            background-color: #fff;
            -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            content: '';
            z-index: -1;
        }
    }
}


