.result-card {
    .card {
        border-radius: 0;
        border: none;
        background-color: transparent;
        width: 100%;

        img {
            width: 100%;
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        }

        .card-btn {
            position: absolute;
            bottom: 3vh;
            font-size: 1.5vw;
            //line-height: 1.1;
            width: 70%;
        }
    }

    .card:after {
        display: block;
        position: absolute;
        bottom: -.625rem;
        left: 1.25rem;
        width: calc(100% - 2.5rem);
        height: 2.1875rem;
        background-color: #fff;
        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
        content: '';
        z-index: -1;
    }
}
