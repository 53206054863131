.szechenyi-logo {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 15%;
    height: auto;
    z-index: 99999;
    box-shadow: none!important;
}

body {
    overflow-x: hidden!important;
}

#scrollToTopBtn {
    display: none;
    position: fixed;
    padding: 30px;
    left: 10px;
    bottom:10px;
    content: '';
    width: 40px;
    height: 40px;
    background: #fff;
    z-index: 1000000;
    opacity: 0.8;
    box-shadow: 0 2px 5px .01px $light-gray;

    .scrollToTopBtn-inner {
        content: '';
        position: absolute;
        width: 20px;
        height: 3px;
        top:28px;
        left:26px;
        background: $light-gray;
        transform: rotate(45deg);
    }

    .scrollToTopBtn-inner:before {
        content: '';
        position: absolute;
        width: 21px;
        height: 3px;
        top: 9px;
        left:-10px;
        background: $light-gray;
        transform: rotate(-90deg);
    }
}

#scrollToTopBtn:hover {
    opacity: 1;
}

@include media-breakpoint-down(lg) {
    .szechenyi-logo {
        width: 30%;
    }
}

@include media-breakpoint-down(md) {
    .szechenyi-logo {
        width: 35%;
    }
}

@include media-breakpoint-down(sm) {
    .szechenyi-logo {
        width: 35%;
    }
}
