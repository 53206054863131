#result-card-project-page{

    width: 100%;
    margin: auto;

    .col {


        .card {
            display: block;
            margin: 3rem auto;
            border-radius: 0;
            //border: 2rem solid $pure-white;
            border: 0;
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            z-index: 2;
            text-align: center;
            width: 350px;

            .card-img-top {
                border-radius: 0;
                //border: 2rem solid $pure-white;
            }

            .card-body {
                background: $pure-white;

                .card-text {
                    width: 70%;
                    margin: 0 auto;
                    text-align: justify;
                    padding-bottom: 1rem;
                    font-size: $font-size*0.75;
                }
            }
        }

        .card:hover {
            transform: scale(1.05, 1.05);
            transition: all .5s ease-in-out;
        }

        .card:after {
            display: block;
            position: absolute;
            bottom: -.625rem;
            left: 1.25rem;
            width: calc(100% - 2.5rem);
            height: 2.1875rem;
            background-color: $white;
            -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
            content: '';
            z-index: -1;
        }
    }
}

@include media-breakpoint-down(xl) {
    #result-card-project-page {

        width: 100%;

        .card:hover {
            transform: none !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    #result-card-project-page {

        width: 100%;

        .card {

        }
    }
}

@include media-breakpoint-down(md) {
    #result-card-project-page {

        .card{
            width: 300px;
        }
    }
}
