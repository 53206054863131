.d_table {

    width: 90%;
    margin: 3rem auto;
    font-size: 1rem;
    box-shadow: $shadow;
    background-color: $pure-white;

    .table {
        thead {
            background-color: $navbar-font;
            color: $white;
        }

        tbody{
            th{
                background-color: $light-gray;
                color: $white;
            }
            .row_head{
                width:1%;
            }
        }
    }
}
