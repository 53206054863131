@import 'colors.scss';
@import 'fonts.scss';
@import 'blocks/headings';
@import 'blocks/buttons';
@import 'blocks/parallax';
@import 'blocks/project-card';
@import 'blocks/result-card';
@import 'blocks/counter';
@import 'blocks/timeline';
@import 'footer';
@import 'blocks/map';
@import 'blocks/mosaic';
@import 'blocks/gallery-section';
@import 'blocks/landing-img.scss';
@import 'blocks/head-title.scss';

body{
    z-index: auto;
    font-family: 'OpenSans-Regular', sans-serif;
    font-size: 0.75rem;
    color: $navbar-font;
    letter-spacing: 0.08em;
    font-weight: 500;
    transition: color .3s;
    text-decoration: none!important;
    width: 100%;
    background: $pure-white;

}

.container-fluid.head-title {

}

.container-landing-img {

}

.container-fluid.projects {

    position: relative;

    .section.project {
        min-height: 100vh;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        padding: 0;

        h2 {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }

    #result {
        visibility: hidden;
        position: absolute;
        bottom: 90px;
        z-index: -1000;
    }
}

.container-fluid.results {
    width: 100%;
    margin:0;
    background: $pure-white;
    .section.results {
        min-height: 100vh;
        background: $pure-white;
    }
}

.container-fluid.counter {
    position: relative;
    #g-map {
        visibility: hidden;
        position: absolute;
        bottom: 70px;
        z-index: -1000;
    }
}

.container-fluid.map {

    position: relative;
    .section.map {
        min-height: 100vh;
        text-align: center;
        align-items: center;
        background-color: $pure-white;
        padding-bottom: 5rem!important;

        .heading {
            background: $white;
        }
    }
    #timeline {
        visibility: hidden;
        position: absolute;
        bottom: 70px;
        z-index: -1000;
    }
}

.container-fluid.timeline-wrapper{
    background: $white;
    margin-bottom: 5rem;
    position: relative;

    .section.timeline {
        //background-color: #f5f5f5;
        padding-bottom: 3rem;
    }
    #gallery {
        visibility: hidden;
        position: absolute;
        bottom: 10px;
        z-index: -1000;
    }
}

.container-fluid.gallery {

    //background: $pure-white;

    .section.gallery {
        width: 80%;
        padding-bottom: 5rem;
    }
}

@include media-breakpoint-down(xl) {
    .container-fluid.gallery {
        .section.gallery {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(lg) {
    .container-fluid.head-title {
        .d-inline {
            padding: 0.5rem;

            .btn.primary {
                margin-bottom: 1rem;
            }

            p {
                text-align: justify;
                margin-bottom: 0.5rem;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
            }
        }
    }

}
