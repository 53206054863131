.d_breadcrumb{
    width: 90%;
    margin: 3rem auto;
    font-size: 1.25rem;

    .breadcrumb{
        .breadcrumb-item{

            a {
                color: $navbar-font;
                text-decoration: none !important;
            }
        }
    }
}
