@font-face {
    font-family: 'OpenSans-Bold';
    src: url("../../assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-BoldItalic';
    src: url("../../assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url("../../assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-ExtraBoldItalic';
    src: url("../../assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Italic';
    src: url("../../assets/fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url("../../assets/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-LightItalic';
    src: url("../../assets/fonts/Open_Sans/OpenSans-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url("../../assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url("../../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-SemiBoldItalic';
    src: url("../../assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
}
