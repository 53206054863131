.container-fluid.head-title {
    min-height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0;
    background: $pure-white;

    .d-inline {
        padding: 5rem;

        .title {
            margin-bottom: 3rem;
            h2 {
                margin: 0 auto;
            }
        }

        .btn.primary {
            margin: 1rem;

            &:focus {
                box-shadow: none;
            }
        }

        h2{
            margin: 2rem 0 2rem;
        }

        p {
            font-size: 1rem;
            text-align: left;
            margin-bottom: 1rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            width: 75%;
        }

        .collapsing {
            transition: all 1s ease-in-out;
        }
    }
}

@include media-breakpoint-down(lg) {
    .container-fluid.head-title {

        padding: 5rem 0 5rem;

        .d-inline {

            h2{
                margin: 2rem 0 2rem;
            }

            p {
                text-align: left!important;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .container-fluid.head-title {

        padding: 4rem 0 4rem;

        .d-inline {

            h2{
                margin: 1.5rem 0 1.5rem;
            }

            p {
                text-align: left!important;
            }
        }
    }
}
