.counter {
    width: 100%;
    min-height: 12rem;
    background-color: $green;

    .col {
        margin: 20px 20px;
        width:25%;
        text-align: center;
        padding: 0;

        img {
            width: 70px;
            height: 70px;
            vertical-align: middle;
        }

        h4 {
            font-size: 2.25rem;
            white-space: nowrap;
            font-variant-caps: all-small-caps;
            color: white;
            //text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
            font-family: 'Montserrat-ExtraBold', sans-serif;
            margin:0!important;
        }

        h5 {
            font-size: 1.75rem;

            font-variant-caps: all-small-caps;
            color: white;
            //text-shadow: 0 0 1.5rem #000000, 2px 2px 4px #000000;
            font-family: 'OpenSans-Light', sans-serif;
        }
    }

    #counter-num {

    }
}
