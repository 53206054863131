.navbar {
    background: #fdfdfe;
    z-index: 1000000;
    transition: all 1s ease-in-out;
    font-weight: 600;

    .navbar-toggler {
        border: none;

        &:focus {
            box-shadow: none;
        }

        .menu-btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            transition: all .5s ease-in-out;
            box-shadow: 0 2px 5px .01px $light-gray;
            //border: 3px solid $black-gray;

            .menu-btn__burger {
                width: 25px;
                height: 3px;
                background: $light-gray;
                //border-radius: 5px;
                //box-shadow: 0 2px 5px $gray;
                transition: all .5s ease-in-out;
            }

            .menu-btn__burger::before,
            .menu-btn__burger::after {
                content: '';
                position: absolute;
                width: 25px;
                height: 3px;
                background: $light-gray;
                //border-radius: 5px;
                //box-shadow: 0 2px 5px $gray;
                transition: all .5s ease-in-out;
            }

            .menu-btn__burger::before {
                transform: translateY(-7px) translateX(-12px);
            }

            .menu-btn__burger::after {
                transform: translateY(7px) translateX(-12px);
            }
        }

        /*
            Animation
             */
        .menu-btn.open {
            .menu-btn__burger {
                transform: translateX(-62px);
                background: transparent;
                box-shadow: none;
            }

            .menu-btn__burger::before {
                transform: rotate(45deg) translate(35px, -35px);
            }

            .menu-btn__burger::after {
                transform: rotate(-45deg) translate(35px, 35px);
            }
        }
    }

    .navbar-collapse{

        padding-right: 1rem;
        .navbar-nav {

            .nav-item {
                margin: 0 2px;
                padding: 2px 10px;
                border-radius: 2px;

                .nav-link {
                    color: inherit;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: $font-size*0.75;
                }
            }

            .nav-item:hover {
                background: $green;
                color: $white;
                box-shadow: 0px 5px 15px 0.1px $light-gray;
            }

            /*
            optional
            add to class nav-item orange/purple
             */

            .nav-item.orange {
                background: $orange !important;
                color: $white !important;
            }

            .nav-item.purple {
                background: $purple !important;
                color: $white !important;
            }
        }
    }

    .navbar-collapse.collapsing,
    .navbar-collapse.show {
        padding-right: 0;
        height: 100vh !important;
    }

    /*
    spacer on right side
    */
    .navbar-spacer{
        height:80px;
        width:80px;
    }
}

@include media-breakpoint-down(lg) {
    .navbar {

    }
}


@include media-breakpoint-down(sm) {
    .navbar {

    }
}
