.accordion-gallery {

    width: 60%;
    margin: 0 auto;
    position: relative;

    .accordion.accordion-flush {

        .accordion-item {

            position: relative;

            .gallery-anchor {
                visibility: hidden;
                position: absolute;
                top: -125px;
            }

            border: 0;
            margin-bottom: 3rem;

            .accordion-header {

                background-color: $pure-white;

                .accordion-button.gallery {

                    background-color: $pure-white;
                    padding: 0;
                    color: $navbar-font;
                    display: block;
                    box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);

                    .cover-image {

                        width: 100%;
                        min-width: 500px;
                        height: auto;
                        min-height: 500px;
                        max-height: 600px;
                        overflow: hidden;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .album-title {

                        width: 100%;
                        margin: 0 auto;
                        background-color: $pure-white;
                        text-align: center;
                        font-size: $font-size*2;
                        font-variant-caps: all-small-caps;
                        font-family: 'Montserrat-ExtraBold', sans-serif;
                        padding: 0.5rem 0 0.5rem;
                        color: $green;
                        font-weight: 900;
                        line-height: 1.1;
                    }

                    &:after {

                        display: block;
                        position: absolute;
                        bottom: -.625rem;
                        left: 1.25rem;
                        width: calc(100% - 2.5rem);
                        height: 2.1875rem;
                        transform: none;
                        background-image: none;
                        background-color: $pure-white;
                        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                        content: '';
                        z-index: -1;
                    }

                    &:hover {

                        z-index: initial;
                    }

                    &:focus {

                        z-index: initial;
                        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                    }

                    &:not(.collapsed) {

                        background-color: $pure-white;
                        -webkit-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);
                        box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgb(64 64 64 / 9%);

                        &::after {
                            background-image: initial;
                            transform: initial;
                        }
                    }
                }
            }

            .accordion-collapse {

                .accordion-body {

                    background-color: $pure-white;
                    border: 0;
                    box-shadow: none;
                    padding: 2rem 0 1rem;
                    margin: auto;

                    .album-images {

                        margin: 1rem;
                        max-width: 20%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .accordion-gallery {

        width: 80%;

        .accordion.accordion-flush {

            .accordion-item {

                .accordion-header {

                    .accordion-button.gallery{

                        .cover-image {

                        }

                        .album-title {

                        }
                    }
                }

                .accordion-collapse {

                    .accordion-body {

                        .album-images {

                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .accordion-gallery {

        width: 80%;

        .accordion.accordion-flush {

            .accordion-item {

                .accordion-header {

                    .accordion-button.gallery{

                        .cover-image {

                        }

                        .album-title {

                        }
                    }
                }

                .accordion-collapse {

                    .accordion-body {

                        .album-images {

                        }
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(sm) {
    .accordion-gallery {

        width: 100%;

        .accordion.accordion-flush {

            .accordion-item {

                .accordion-header {

                    .accordion-button.gallery{

                        .cover-image {

                        }

                        .album-title {

                        }
                    }
                }

                .accordion-collapse {

                    .accordion-body {

                        .album-images {

                        }
                    }
                }
            }
        }
    }
}
