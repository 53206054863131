
.btn.primary {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
    border-radius: 2px;
    background: $green;
    color: $white;
}

.btn.primary:hover {
    box-shadow: 0px 5px 15px 0.1px $light-gray;
}

.btn.primary.dark-hover {
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 2px;
    background: $green;
    color: $white;
}

.btn.primary.dark-hover:hover {
    box-shadow: 0px 5px 15px 0.1px $gray;
}

.btn.secondary {
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 2px;
    background: $orange;
    color: $white;
}

.btn.secondary:hover {
    box-shadow: 0px 5px 15px 0.1px $light-gray;
}

.btn.third {
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 2px;
    color: $navbar-font;
}

.btn.third:hover {
    background: $orange;
    color: $white;
    box-shadow: 0px 5px 15px 0.1px $light-gray;
}

.btn.purple {
    background: $purple;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 2px;
    color: $white;
}
.btn.purple:hover {
    box-shadow: 0px 5px 15px 0.1px $light-gray;
}

.btn.timeline {
    border-radius: 0!important;
    background: #fff;
    color: $navbar-font;
    width:100%;
}

.btn.timeline:hover {
    z-index: 1;
    box-shadow: 0px 2px 15px 0.1px $light-gray;
}

.btn.timeline.active {
    background: $green;
    color: $white;
}






